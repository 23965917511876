import { useRef } from "react";
import { useEffect } from "react";


export const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  export const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
  }

  export function usePrev(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
  
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
  
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }
  