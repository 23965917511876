import React from "react";
import "./SocialMedia.css";
import { socialMediaData } from "../../portfolio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { style } from "glamor";
import { generateKey } from "../../utils/Utils";
export default function SocialMediaList({ theme, children }) {
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  
  const mail = socialMediaData.find(x => x.name === "");
  return (
    <div className="mt-8 text-center lg:text-left md:text-left">
     {socialMediaData.map((x, index) => (
      x.name !== "" && 
      <a href={x.link} key={generateKey(index+"")} target='_blank' className={
        index == 0 ?"text-gray-400 hover:text-gray-500":"ml-6 text-gray-400 hover:text-gray-500"
      }>
      <span className="sr-only">{x.name}</span>
      <FontAwesomeIcon size="1x"  icon={x.icon} />
    </a>
     ))}
     <a href={mail.link} target='_blank' className="ml-6 text-gray-400 hover:text-gray-500">
      <span className="sr-only">Mail</span>
      <FontAwesomeIcon  size="1x"  icon={mail.icon} />
    </a>
  </div>
  );
}
