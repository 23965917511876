import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { classNames } from "../utils/Utils";
import { greeting } from "../portfolio";
const Contact = (props) => {
  const [data, setData] = useState({});

  const theme = props.theme;
  return (
    <main
      className={classNames(
        theme.name === "dark" ? "bg-[#25262a]" : "bg-gray-100",
        "blockpy-6 px-4 sm:p-6 md:py-10 md:px-8 w-screen -ml-3"
      )}
    >
      <div className="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
        <div className="relative p-3 col-start-1 mb-14
         row-start-1  rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none  sm:p-0 lg:row-start-1">
          <h1
            className={classNames(
              theme.name === "dark" ? "text-white" : "text-dark",
              "mt-1 text-lg font-semibold md:text-2xl"
            )}
          >
            Got a Project ?<br></br>
            Let's Talk.
          </h1>
          <p
            className={classNames(
              theme.name === "dark" ? "text-gray-500" : "text-gray-900",
              "mt-2 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1"
            )}
          >
            Lets Make something amazing together.
          </p>
          <div className="mt-9 flex text-[#64f4ac] font-inter font-bold">
            <p className="pr-7">{greeting.mail.replace("mailto:","")}</p>
            <a href={greeting.mail} className="">
            <span>
            <FontAwesomeIcon
                        icon={Icons.faArrowRight}
                        className="block h-6 w-6 bg-transparent text-[#64f4ac] animate-bounce active:text-[#44a876]"
                        aria-hidden="true"
                      />
            </span>
            </a>
          </div>
        </div>
        <div className="">
          <div
            className={classNames(
              theme.name === "dark" ? "text-white" : "text-dark",
              "mt-1 text-lg font-semibold md:text-2xl mb-9"
            )}
          >
            Estimate Your Project? <br></br>
            Let me know here
          </div>

          <div className="mb-6">
            <label
              htmlFor="fullName"
              className={classNames(
                theme.name === "dark" ? "text-white" : "text-dark",
                "font-medium text-base  block mb-3"
              )}
            >
              What's your Name?
            </label>
            <input
              placeholder="John Doe"
              value={data.fullName}
              onChange={(e) => setData({ ...data, fullName: e.target.value })}
              className="-mt-2 bg-transparent   border-b-2 border-b-[#7e7e7e] outline-none active:border-none h-10 w-full  placeholder:text-[#7e7e7e]/[0.6] "
              type="text"
              id="fullName"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="fullName"
              className={classNames(
                theme.name === "dark" ? "text-white" : "text-dark",
                "font-medium text-base  block mb-3"
              )}
            >
              Your e-mail address?
            </label>
            <input
              placeholder="johndoe@awesomeinc.com"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              className="-mt-2 bg-transparent   border-b-2 border-b-[#7e7e7e] outline-none active:border-none h-10 w-full  placeholder:text-[#7e7e7e]/[0.6] "
              type="text"
              id="email"
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="fullName"
              className={classNames(
                theme.name === "dark" ? "text-white" : "text-dark",
                "font-medium text-base  block mb-3"
              )}
            >
              Tell me about your project.
            </label>
            <textarea
              placeholder="I want to build a spaceship"
              value={data.project}
              onChange={(e) => setData({ ...data, project: e.target.value })}
              className="-mt-2 bg-transparent    border-b-2 border-b-[#7e7e7e] outline-none active:border-none h-24 w-full  placeholder:text-[#7e7e7e]/[0.6] "
              type="text"
              id="project"
            ></textarea>
          </div>

          <button className="float-right">
            <span>
            <FontAwesomeIcon
                        icon={Icons.faArrowRight}
                        className="block h-6 w-6 bg-transparent text-[#64f4ac] animate-bounce active:text-[#44a876]"
                        aria-hidden="true"
                      />
            </span>
          </button>
        </div>
      </div>
    </main>
  );
};
export default Contact;
