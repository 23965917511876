import React, { useState } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { settings } from "../../portfolio.js";
import { Disclosure } from "@headlessui/react";
import { CgSun } from "react-icons/cg/";
import { HiMoon } from "react-icons/hi";
import { style } from "glamor";
import logo from "../../assets/images/logo.png";
import logo_black from "../../assets/images/logo_black.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import GrowingCircleCanvas from "../animations/GrowingCircleCanvas";

const navigation = [
  { name: "Home", href: "#", current: true, styleClass: "homei" },
  { name: "Stories", href: "#", current: false, styleClass: "ec" },
  { name: "Services", href: "#", current: false, styleClass: "xp" },
  { name: "Works", href: "#", current: false, styleClass: "projects" },
  { name: "Contacts", href: "#", current: false, styleClass: "cr" },
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

function Header({ theme, setTheme }) {
  const [navData, setNavData] = useState(navigation);
  const styles = style({
    cursor: "pointer",
    backgroundColor: "#59af84",
    transition: "all 0.2s ease-in-out",
  });

  const link = settings.isSplash ? "/splash" : "home";

  const [currTheme, setCurrTheme] = useState(theme);

  

  const icon =
    theme.name === "dark" ? (
      <HiMoon
        strokeWidth={1}
        size={25}
        color={theme.name === "light" ? "#2d2e32" : "rgb(255 255 255)"}
      />
    ) : (
      <CgSun
        strokeWidth={1}
        size={25}
        color={theme.name === "light" ? "#2d2e32" : "rgb(255 255 255)"}
      />
    );

  return (
    <Fade top duration={1000} distance="20px">
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <FontAwesomeIcon
                        icon={Icons.faXmark}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={Icons.faBars}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-12 w-auto lg:hidden"
                      src={theme.name === "light" ? logo_black : logo}
                      alt="CrownCodes"
                    />
                    <img
                      className="hidden h-12 w-auto lg:block"
                      src={theme.name === "light" ? logo_black : logo}
                      alt="CrownCodes"
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navData.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "active" : item.styleClass,
                            theme.name === "light"
                              ? "text-black"
                              : "text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <GrowingCircleCanvas />
               
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 text-black">
                {navData.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? "active" : item.styleClass,
                      theme.name === "light" ? "text-black" : "text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </Fade>
  );
}

export default Header;
