import "react-app-polyfill/ie11"; // required for IE11
import "react-app-polyfill/stable"; // required for IE11
import React, { Suspense, lazy } from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import ReactDOM from 'react-dom/client';
import './index.css';
import { BaseProvider, LightTheme } from "baseui";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import * as serviceWorker from "./serviceWorker";

import App from './App';
import reportWebVitals from './reportWebVitals';

const engine = new Styletron();

const RootContainer = (
  <React.StrictMode>
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      <App />
    </BaseProvider>
  </StyletronProvider>
</React.StrictMode>
)


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(RootContainer, rootElement);
} else {
  render(RootContainer, rootElement);
}

const prefersColorSchemeWatcher = window.matchMedia("(prefers-color-scheme: dark)");

prefersColorSchemeWatcher.addEventListener("change", () => {
  const favicon = document.querySelector('link[rel="icon"]');
  favicon.href = null;
  favicon.href = "/favicon.ico";
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
