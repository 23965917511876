import React from "react";
import Header from "../components/header/Header";
import Greeting from "../components/greeting/Greeting";
import Footer from "../components/footer/Footer";
import Contact from "./Contact";

function Home({theme, setTheme}) {
  console.log({theme, setTheme}, "home");
  return (
    <div style={{
      width: '100%',
      margin: '0px auto',
      padding: '0px 10px'
    }}>
      <Header theme={theme} setTheme={setTheme} />
      <Greeting theme={theme} />
      {/* <Skills theme={theme} /> */}
      <Contact theme={theme} />
      <Footer theme={theme} />
    </div>
  );
}

export default Home;
