import React from "react";
import "./Greeting.css";
// import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting, experienceData } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import { style } from "glamor";
import { useNavigate } from "react-router-dom";
import MyImage from "../../assets/images/me-half.png";
import SocialMediaList from "../socialMedia/SocialMediaList";

export default function Greeting(props) {
  const theme = props.theme;
  const navigate = useNavigate();

  const styles = style({
    padding: "5px 5px",
    margin: "0px auto",
    borderRadius: "5px",
    backgroundColor: theme.accentColor,
  });
  const buttonStyle = style({
    textDecoration: "underline",
    color: theme.accentColor,
    marginTop: "25px",
    marginBottom: "20px",
    width: "200px",
    height: "50px",
    fontWeight: "bold",
    fontFamily: "monospace",
    fontSize: "17px",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
  });

  return (
    <Fade bottom duration={2000} distance="40px">
     <div className="mt-8" id="greeting">
        <div className="md:flex block p-24 pl-24 columns-1">
           <div className="w-full">
            <h1 className="text-black font-sans font-bold text-center lg:text-left md:text-left mt-4 mb-4 hover:underline">
              <span {...styles}>{greeting.full_name}</span>
            </h1>

            <p
              className="greeting-text-p subTitle"
              style={{ color: theme.primaryText }}
            >
              {greeting.subTitle}
            </p>
            <p
              className="text-center lg:text-left md:text-left font-mono mt-2"
              style={{ color: theme.secondaryText }}
            >
              {greeting.caption}
            </p>
            <SocialMediaList theme={theme} />
            <div className="portfolio-repo-btn-div">
              <p
                {...buttonStyle}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                LET'S HAVE A CHAT!
              </p>
            </div>
          </div>

          <div className="w-full h-auto aspect-video">
            {greeting.useImage ? (
              <img src={MyImage}></img>
            ) : (
              <FeelingProud theme={theme} />
            )}
          </div>
        </div>
        <div className="w-full -mt-36 pt-12 md:pr-28 md:pl-28 pr-36 pl-36 columns-1 mb-8  justify-start flex grid-cols-2 gap-10">
         
         <ul>
          <li className="mr-10">
          <div  className="grid grid-cols-2 ">
            <div className="font-sans text-6xl">{experienceData.yearsOfExperience}</div>
            <div style={{ color: theme.secondaryText }} 
            className="font-mono text-xs mt-5 -mr-5">
              YEARS <br /> EXPERIENCE
            </div>
          </div>
          </li>
          <li>
          <div  className="grid grid-cols-2">
            <div className="font-sans text-6xl">
              {experienceData.numOfCompletedProjects}
            </div>

            <div style={{ color: theme.secondaryText }} 
            className="font-mono text-xs mt-5 -ml-5">
              PROJECTS COMPLETED <br />
              IN {experienceData.numOfCountries} COUNTRIES
            </div>
          </div>
          </li>
         </ul>
        </div>
      </div>
    </Fade>
  );
}
